import Link from 'next/link';
import { PATH_ABOUT, PATH_DASHBOARD, PATH_PAGE } from '~/routes/paths';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/shadcn/ui/accordion';
import { Button } from '~/shadcn/ui/button';
import { Mail, FileText, Info, HeartHandshake } from 'lucide-react';
import Logo from '../logo';
import { Card } from '../ui/card';
import { SheetClose } from '../ui/sheet';

type Props = {
  dict: Dictionary;
  lang: AppLocale;
};

export function HomeSidebarContent({ dict, lang }: Props) {
  return (
    <div className="flex flex-col w-full gap-8 py-6">
      <Link href={PATH_PAGE.home(lang)}>
        <Card className="flex flex-row items-center px-4 py-4 border-none gap-4 mx-[-16px] shadow-md rounded-lg">
          <Logo disabledLink className="w-10 h-10" />
          <div className="flex flex-col text-center font-light text-sm">
            <span className="leading-tight">Stiftelsen</span>
            <span className="leading-tight">Kempe-Carlgrenska</span>
            <span className="leading-tight">Fonden</span>
          </div>
        </Card>
      </Link>
      <nav className="flex flex-col gap-4 text-sm text-muted-foreground">
        <div className="flex gap-4 items-center  rounded-md px-4 mx-[-16px]">
          <Info />
          <SheetClose asChild>
            <Button asChild variant="ghost" className="w-full justify-start">
              <Link href={PATH_PAGE.about(lang)}>{dict['Om oss']}</Link>
            </Button>
          </SheetClose>
        </div>

        <Accordion type="multiple">
          <AccordionItem value="about" className="border-none">
            <AccordionTrigger className="hover:no-underline  rounded-md px-4 py-2 mx-[-16px]">
              <div className="flex gap-7 items-center ">
                <HeartHandshake />
                {dict['Våra anslag']}
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <ul className="list-disc ml-6">
                <li>
                  <SheetClose asChild>
                    <Button
                      asChild
                      variant="ghost"
                      className="w-full justify-start py-2"
                    >
                      <Link href={PATH_ABOUT.students(lang)}>
                        {dict.Musikstuderande}
                      </Link>
                    </Button>
                  </SheetClose>
                </li>
                <li>
                  <SheetClose asChild>
                    <Button
                      asChild
                      variant="ghost"
                      className="w-full justify-start py-2"
                    >
                      <Link href={PATH_ABOUT.education(lang)}>
                        {dict['Utbildning']}
                      </Link>
                    </Button>
                  </SheetClose>
                </li>
                <li>
                  <SheetClose asChild>
                    <Button
                      asChild
                      variant="ghost"
                      className="w-full justify-start py-2"
                    >
                      <Link href={PATH_ABOUT.childcare(lang)}>
                        {dict['Barn med särskilda behov']}
                      </Link>
                    </Button>
                  </SheetClose>
                </li>
                <li>
                  <SheetClose asChild>
                    <Button
                      asChild
                      variant="ghost"
                      className="w-full justify-start py-2"
                    >
                      <Link href={PATH_ABOUT.integration(lang)}>
                        {dict['Integrationsarbete']}
                      </Link>
                    </Button>
                  </SheetClose>
                </li>
                <li>
                  <SheetClose asChild>
                    <Button
                      asChild
                      variant="ghost"
                      className="w-full justify-start py-2"
                    >
                      <Link href={PATH_ABOUT.research(lang)}>
                        {dict.Forskare}
                      </Link>
                    </Button>
                  </SheetClose>
                </li>
              </ul>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        <div className="flex gap-4 items-center  rounded-md px-4 mx-[-16px]">
          <FileText />
          <SheetClose asChild>
            <Button asChild variant="ghost" className="w-full justify-start">
              <Link href={PATH_DASHBOARD.applications.root(lang)}>
                {dict.Ansökningar}
              </Link>
            </Button>
          </SheetClose>
        </div>

        <div className="flex gap-4 items-center  rounded-md px-4 mx-[-16px]">
          <Mail />
          <SheetClose asChild>
            <Button asChild variant="ghost" className="w-full justify-start">
              <Link href={PATH_PAGE.contact(lang)}>{dict.Kontakt}</Link>
            </Button>
          </SheetClose>
        </div>
      </nav>
    </div>
  );
}
