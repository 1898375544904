'use client';

import { SeverErrorIllustration } from '~/assets/illustrations';
import { Button } from '~/shadcn/ui/button';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

export default function Page500({
  dict,
  retry = () => {
    console.warn('retry not implemented');
  },
  showBackButton = false,
  showHomeButton = false,
  showRetryButton = false,
}: {
  lang?: AppLocale;
  dict: Dictionary;
  retry?: () => void;
  showBackButton?: boolean;
  showHomeButton?: boolean;
  showRetryButton?: boolean;
}) {
  const router = useRouter();

  return (
    <main className="flex min-h-[calc(100vh-(--spacing(32)))] flex-1 flex-row  bg-muted/40">
      <div className="w-full overflow-x-hidden flex flex-col justify-center items-center">
        <div>
          <h1 className="text-3xl font-bold mb-2">{dict['Serverfel']}</h1>
        </div>

        <div className="flex flex-col gap-y-2">
          <h2 className="text-foreground text-center">
            {dict['Något gick fel på vår sida!']}
            <br />
            {
              dict[
                'Fungerar det inte även om du har försökt igen? Vänligen kontakta oss och beskriv vad du gjorde.'
              ]
            }
          </h2>
        </div>

        <div>
          <SeverErrorIllustration className="h-72 my-5" />
        </div>

        {showBackButton && (
          <Button
            asChild
            size="lg"
            variant="default"
            onClick={() => router.back()}
          >
            {dict['Gå tillbaka']}
          </Button>
        )}

        {showHomeButton && (
          <Button asChild size="lg" variant="default">
            <Link href="/">{dict['Gå till startsidan']}</Link>
          </Button>
        )}

        {showRetryButton && (
          <Button asChild size="lg" variant="default" onClick={retry}>
            {dict['Försök igen']}
          </Button>
        )}
      </div>
    </main>
  );
}
