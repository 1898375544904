'use client';

import { PageNotFoundIllustration } from '~/assets/illustrations';
import { Button } from '~/shadcn/ui/button';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

// ----------------------------------------------------------------------

export default function View404({
  dict,
  retry = () => {
    console.warn('retry not implemented');
  },
  showBackButton = false,
  showHomeButton = false,
  showRetryButton = false,
}: {
  dict: Dictionary;
  lang: AppLocale;
  retry?: () => void;
  showBackButton?: boolean;
  showHomeButton?: boolean;
  showRetryButton?: boolean;
}) {
  const router = useRouter();

  return (
    <main className="flex min-h-[calc(100vh-(--spacing(32)))] flex-1 flex-row  bg-muted/40">
      <div className="w-full overflow-x-hidden flex flex-col justify-center items-center">
        <h1 className="text-3xl font-bold mb-2">
          {dict['Den sidan finns inte.']}
        </h1>

        <h2 className="text-foreground text-center">
          {
            dict[
              'Vi kunde inte hitta sidan du letade efter. Kanske är adressen felstavad? Var god kontrollera stavningen och försök igen.'
            ]
          }
        </h2>

        <PageNotFoundIllustration className="h-72 my-5" />

        {showBackButton && (
          <Button
            asChild
            size="lg"
            variant="default"
            onClick={() => router.back()}
          >
            {dict['Gå tillbaka']}
          </Button>
        )}

        {showHomeButton && (
          <Button asChild size="lg" variant="default">
            <Link href="/">{dict['Gå till startsidan']}</Link>
          </Button>
        )}

        {showRetryButton && (
          <Button asChild size="lg" variant="default" onClick={retry}>
            {dict['Försök igen']}
          </Button>
        )}
      </div>
    </main>
  );
}
