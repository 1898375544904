import Footer from './home-footer';
import HomeNavbar from './home-navbar';

export default function HomeLayout({
  children,
  lang,
  dict,
}: {
  children: React.ReactNode;
  lang: AppLocale;
  dict: Dictionary;
}) {
  return (
    <>
      <HomeNavbar lang={lang} dict={dict} />
      <main className="flex min-h-[calc(100vh-(--spacing(32)))] flex-1 flex-row  bg-muted/40">
        <div className="w-full overflow-x-hidden">{children}</div>
      </main>
      <Footer />
    </>
  );
}
