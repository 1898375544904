import Link from 'next/link';
import { ChevronDown, Menu } from 'lucide-react';
import { Button } from '~/shadcn/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/shadcn/ui/dropdown-menu';
import { ModeToggle } from '../theme-provider/toggle';
import { PATH_ABOUT, PATH_DASHBOARD, PATH_PAGE } from '~/routes/paths';
import Logo from '~/shadcn/logo';
import { Sheet, SheetContent, SheetTrigger } from '~/shadcn/ui/sheet';
import { HomeSidebarContent } from './home-sidebar-content';
import LanguagePopover from './language-popover';

type Props = {
  dict: Dictionary;
  lang: AppLocale;
};

export default function HomeNavbar({ lang, dict }: Props) {
  return (
    <header className="sticky z-10 top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
      <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
        <Logo />
        <Button
          variant="link"
          asChild
          className="text-muted-foreground dark:text-foreground transition-colors hover:text-foreground"
        >
          <Link href={PATH_PAGE.about(lang)}>{dict['Om oss']}</Link>
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="link"
              className="text-muted-foreground dark:text-foreground hover:no-underline transition-colors hover:text-foreground"
            >
              <span>{dict['Våra anslag']}</span>
              <ChevronDown className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{dict.Musikstuderande}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild className="ml-2">
              <Link href={PATH_ABOUT.students(lang)}>
                {dict.Musikstuderande}
              </Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuLabel>{dict.Organisationer}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild className="ml-2">
              <Link href={PATH_ABOUT.education(lang)}>
                {dict['Utbildning']}
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild className="ml-2">
              <Link href={PATH_ABOUT.childcare(lang)}>
                {dict['Barn med särskilda behov']}
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild className="ml-2">
              <Link href={PATH_ABOUT.integration(lang)}>
                {dict['Integrationsarbete']}
              </Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuLabel>{dict.Forskare}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild className="ml-2">
              <Link href={PATH_ABOUT.research(lang)}>{dict.Forskare}</Link>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <Button
          variant="link"
          asChild
          className="text-muted-foreground dark:text-foreground transition-colors hover:text-foreground"
        >
          <Link href={PATH_DASHBOARD.applications.root(lang)}>
            {dict['Ansökningar']}
          </Link>
        </Button>
        <Button
          variant="link"
          asChild
          className="text-muted-foreground dark:text-foreground transition-colors hover:text-foreground"
        >
          <Link href={PATH_PAGE.contact(lang)}>{dict['Kontakt']}</Link>
        </Button>
      </nav>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="w-full max-w-sm">
          <HomeSidebarContent lang={lang} dict={dict} />
        </SheetContent>
      </Sheet>
      <div className="flex w-full items-center justify-end gap-4 md:ml-auto">
        <LanguagePopover />
        <ModeToggle />
      </div>
    </header>
  );
}
