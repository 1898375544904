'use client'; // Error boundaries must be Client Components

import * as Sentry from '@sentry/nextjs';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { availableLocales } from '~/utils/locale';
import { View401, View403, View404, View500 } from '~/sections/error';
import { getDictionarySync } from '~/utils/dictionary-client';
import HomeLayout from '~/shadcn/navigation/home-layout';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const pathname = usePathname();
  const attemptedLocale =
    (pathname.split('/')[1] as AppLocale | undefined) ?? 'sv';

  const lang = availableLocales.includes(attemptedLocale)
    ? (attemptedLocale as AppLocale)
    : 'sv';

  const dict = getDictionarySync(lang);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  if (error.message.startsWith('UNAUTHORIZED')) {
    return (
      <HomeLayout lang={lang} dict={dict}>
        <View401 lang={lang} dict={dict} showBackButton />
      </HomeLayout>
    );
  }

  if (error.message.startsWith('FORBIDDEN')) {
    return (
      <HomeLayout lang={lang} dict={dict}>
        <View403 lang={lang} dict={dict} showBackButton />
      </HomeLayout>
    );
  }

  if (error.message.startsWith('INTERNAL_SERVER_ERROR')) {
    return (
      <HomeLayout lang={lang} dict={dict}>
        <View500 lang={lang} dict={dict} retry={reset} showRetryButton />
      </HomeLayout>
    );
  }

  if (error.message.startsWith('NOT_FOUND')) {
    return (
      <HomeLayout lang={lang} dict={dict}>
        <View404 lang={lang} dict={dict} showBackButton />
      </HomeLayout>
    );
  }

  return (
    <HomeLayout lang={lang} dict={dict}>
      <View500 lang={lang} dict={dict} retry={reset} showRetryButton />
    </HomeLayout>
  );
}
