'use client';

import { UnauthorizedIllustration } from '~/assets/illustrations';
import { Button } from '~/shadcn/ui/button';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

export default function View401({
  dict,
  retry = () => {
    console.warn('retry not implemented');
  },
  showBackButton = false,
  showHomeButton = false,
  showRetryButton = false,
}: {
  lang?: AppLocale;
  dict: Dictionary;
  retry?: () => void;
  showBackButton?: boolean;
  showHomeButton?: boolean;
  showRetryButton?: boolean;
}) {
  const router = useRouter();
  return (
    <main className="flex min-h-[calc(100vh-(--spacing(32)))] flex-1 flex-row  bg-muted/40">
      <div className="w-full overflow-x-hidden flex flex-col gap-y-5 justify-center items-center">
        <h1 className="text-3xl font-bold mb-2">{dict['Ingen åtkomst']}</h1>
        <div className="flex flex-col gap-y-2">
          <h2 className="text-foreground text-center">
            {dict['Du kan inte komma åt denna sida.']}
            <br />
            {
              dict[
                'Verkar det ha blivit ett fel? Vänligen kontakta oss och beskriv vad du gjorde.'
              ]
            }
          </h2>
        </div>

        <div className="flex justify-center items-center">
          <UnauthorizedIllustration className="h-72" />
        </div>

        {showBackButton && (
          <Button size="lg" variant="default" onClick={() => router.back()}>
            {dict['Gå tillbaka']}
          </Button>
        )}

        {showHomeButton && (
          <Button asChild size="lg" variant="default">
            <Link href="/">{dict['Gå till startsidan']}</Link>
          </Button>
        )}

        {showRetryButton && (
          <Button size="lg" variant="default" onClick={retry}>
            {dict['Försök igen']}
          </Button>
        )}
      </div>
    </main>
  );
}
