import enDictionary from '../dictionaries/en';
import svDictionary from '../dictionaries/sv';

const dictionaries: Record<string, Dictionary> = {
  en: enDictionary,
  sv: svDictionary,
};

export const getDictionarySync = (
  locale: keyof typeof dictionaries
): Dictionary => {
  if (locale in dictionaries) {
    return dictionaries[locale] as Dictionary;
  }
  console.error(`Dictionary for locale '${locale}' not found`);
  return dictionaries.sv as Dictionary;
};
